import React from 'react';
import PropTypes from 'prop-types';

// Context
import { SiteProvider } from './src/context/SiteContext';
import { ViewportProvider } from './src/context/ViewportContext';

export const wrapRootElement = ({ element }) => {
    return (


        <SiteProvider value={[]}>
            <ViewportProvider value={[]}>{element}</ViewportProvider>
        </SiteProvider>

    );
};

wrapRootElement.propTypes = { element: PropTypes.any };
