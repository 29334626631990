import React from "react";
import PropTypes from "prop-types";
import { breakpoints } from "../helpers/breakpoints/breakpoints";

const initialState = {
  scrollTop: 0,
  width: 0,
  height: 0,
  currentBreakpoint: "none",
};

export const ViewportContext = React.createContext(initialState);

export class ViewportProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.determineScroll = () => {
      this.setState({
        scrollTop: window.scrollY,
      });
    };

    this.determineViewport = () => {
      const currentBreakpoint = Object.keys(breakpoints).filter((key, index) => {
        return breakpoints[key] < window.innerWidth;
      }).pop();


      this.setState({
        width: window.innerWidth,
        height: window.innerHeight,
        currentBreakpoint: currentBreakpoint || "none"
      });
    };

  }


  async componentDidMount() {
    window.addEventListener("scroll", this.determineScroll);
    window.addEventListener("resize", this.determineViewport);

    const currentBreakpoint = Object.keys(breakpoints).filter((key, index) => {
      return breakpoints[key] < window.innerWidth;
    }).pop();

    this.setState({
      scrollTop: window.scrollY,
      width: window.innerWidth,
      height: window.innerHeight,
      currentBreakpoint: currentBreakpoint || "none"
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.determineScroll);
    window.removeEventListener("resize", this.determineViewport);
  }

  render() {
    const { children } = this.props;

    return (
      <ViewportContext.Provider
        value={{
          ...this.state,
        }}
      >
        {children}
      </ViewportContext.Provider>
    );
  }
}
ViewportProvider.propTypes = {
  children: PropTypes.node,
};

export const ViewportConsumer = ViewportContext.Consumer;
