import React from 'react';
import PropTypes from 'prop-types';

const initialState = {
  theme: "light",
  animateOnce: false,
  activeNavigation: 'home',
  scrollPosition: 0,
  activeModal: '',
};


export const SiteContext = React.createContext(initialState);

export class SiteProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = initialState;
    this.toggleTheme = this.toggleTheme.bind(this)
    this.setAnimated = this.setAnimated.bind(this)
    this.changeNavigation = this.changeNavigation.bind(this)
    this.changeModal = this.changeModal.bind(this)

  }

  componentDidMount() {
    if (window) {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.setState({ theme: "dark" })
      }
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
        const newColorScheme = event.matches ? "dark" : "light";
        this.setState({ theme: newColorScheme })
      });
    }
  }

  toggleTheme = () => {
    this.setState(prevState => {
      return { theme: prevState.theme === "light" ? "dark" : "light" }
    });
  };

  changeModal = (modalName) => {
    this.setState({
      activeModal: modalName
    })
  }

  setAnimated = () => {
    this.setState({
      animateOnce: true,
    });
  };

  changeNavigation = (navigationTarget) => {
    this.setState({ activeNavigation: navigationTarget })
  }

  setScrollPosition = (position) => {
    this.setState({ scrollPosition: position })
  }

  render() {
    const { children } = this.props;

    return (
      <SiteContext.Provider
        value={{
          ...this.state,
          toggleTheme: this.toggleTheme,
          setAnimated: this.setAnimated,
          changeNavigation: this.changeNavigation,
          setScrollPosition: this.setScrollPosition,
          changeModal: this.changeModal,
        }}
      >
        {children}
      </SiteContext.Provider>
    );
  }
}
SiteProvider.propTypes = {
  children: PropTypes.node,
};

export const SiteConsumer = SiteContext.Consumer;
